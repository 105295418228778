import React from "react";
import ClientsSection from "./../components/ClientsSection";
import FeaturesSection2 from "./../components/FeaturesSection2";
import FeaturesSection from "./../components/FeaturesSection";
import "./index.scss";

function IndexPage(props) {
  return (
    <>
      <div className="IndexPage__image-container1">
        <img
          className="IndexPage__bg1"
          src="https://clubavatar.app/site-bg1.jpg"
        ></img>
        <div className="IndexPage__logo-big-container">
          <img className="IndexPage__logo-big1" src="logo1.svg"></img>
        </div>
      </div>
      <ClientsSection
        bg="light"
        textColor="dark"
        size="sm"
        bgImage=""
        bgImageOpacity={1}
        title=""
        subtitle=""
      ></ClientsSection>
      <FeaturesSection2
        bg="white"
        textColor="dark"
        size="md"
        bgImage=""
        bgImageOpacity={1}
        title="Club Avatar Features"
        subtitle=""
      ></FeaturesSection2>
      <FeaturesSection
        bg="white"
        textColor="dark"
        size="md"
        bgImage=""
        bgImageOpacity={1}
        image="https://uploads.divjoy.com/undraw-chatting_2yvo.svg"
      ></FeaturesSection>
    </>
  );
}

export default IndexPage;
