import React from "react";
import Section from "./Section";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Features from "./Features";

function FeaturesSection(props) {
  return (
    <Section
      bg={props.bg}
      textColor={props.textColor}
      size={props.size}
      bgImage={props.bgImage}
      bgImageOpacity={props.bgImageOpacity}
    >
      <Container>
        <Row className="align-items-center">
          <Col className="offset-lg-1 mt-5 mt-lg-0 ">
            <Features
              items={[
                {
                  title: "DJ Live sessions",
                  description: "Join to your favorite DJ live session",
                  iconClass: "fas fa-music",
                  iconColor: "success",
                },
                {
                  title: "Non stop party 24/7",
                  description: "Party never stops",
                  iconClass: "fas fa-bolt",
                  iconColor: "warning",
                },
                {
                  title: "Multiple stages",
                  description: "Select your favorite stage",
                  iconClass: "fas fa-university",
                  iconColor: "primary",
                },
                {
                  title: "Talk rooms",
                  description: "Chat in talk rooms",
                  iconClass: "fas fa-user-plus",
                  iconColor: "success",
                },
                {
                  title: "Game rooms",
                  description: "Play games",
                  iconClass: "fas fa-gamepad",
                  iconColor: "danger",
                },
                {
                  title: "Make your Avatar",
                  description: "Design and define personality of your avatar",
                  iconClass: "fas fa-paint-brush",
                  iconColor: "info",
                },
                {
                  title: "Amazing graphics",
                  description: "Photorealistic graphics and animations",
                  iconClass: "fas fa-thumbs-up",
                  iconColor: "success",
                },
                {
                  title: "Interactive quests",
                  description: "Win prizes in interactive quests",
                  iconClass: "fas fa-trophy",
                  iconColor: "warning",
                },
              ]}
            ></Features>
          </Col>
        </Row>
      </Container>
    </Section>
  );
}

export default FeaturesSection;
