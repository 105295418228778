import React from "react";
import Section from "./Section";
import Container from "react-bootstrap/Container";
import SectionHeader from "./SectionHeader";
import Features2 from "./Features2";

function FeaturesSection2(props) {
  return (
    <Section
      bg={props.bg}
      textColor={props.textColor}
      size={props.size}
      bgImage={props.bgImage}
      bgImageOpacity={props.bgImageOpacity}
    >
      <Container>
        <SectionHeader
          title={props.title}
          subtitle={props.subtitle}
          size={2}
          spaced={true}
          className="text-center"
        ></SectionHeader>
        <Features2
          items={[
            {
              title: "Craft your Avatar",
              description:
                "Design, teach and define personality of your avatar",
              image: "avatar0.png",
            },
            {
              title: "Party rooms",
              description: "Live party in virtual dance rooms",
              image: "party0.png",
            },
            {
              title: "Game rooms",
              description: "Play games and win prizes in game rooms",
              image: "game0.png",
            },
            {
              title: "Talk rooms",
              description:
                "Meet new people and chat with friends in talk rooms",
              image: "friend0.png",
            },
            {
              title: "Multiple stages",
              description: "Choose your favorite scene",
              image: "field0.png",
            },
          ]}
        ></Features2>
      </Container>
    </Section>
  );
}

export default FeaturesSection2;
