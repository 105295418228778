import React from "react";
import NewsletterSection from "./../components/NewsletterSection";
import ContactSection from "./../components/ContactSection";
import Nav from "react-bootstrap/Nav";
import NavDropdown from "react-bootstrap/NavDropdown";
import { LinkContainer } from "react-router-bootstrap";
import Dropdown from "react-bootstrap/Dropdown";
import { useAuth } from "./../util/auth.js";

function ContactPage(props) {
  const auth = useAuth();

  return (
    <>
      <NewsletterSection
        bg="white"
        textColor="dark"
        size="md"
        bgImage=""
        bgImageOpacity={1}
        title="Stay in the know"
        subtitle="Receive our latest articles and feature updates"
        buttonText="Subscribe"
        buttonColor="primary"
        inputPlaceholder="Enter your email"
        subscribedMessage="You are now subscribed!"
        visible={false}
      ></NewsletterSection>
      <ContactSection
        bg="white"
        textColor="dark"
        size="md"
        bgImage=""
        bgImageOpacity={1}
        title="Contact Us"
        subtitle=""
        buttonText="Send message"
        buttonColor="primary"
        showNameField={true}
        inputSize="md"
      ></ContactSection>
      <Nav>
        {auth.user && (
          <NavDropdown id="dropdown" title="Account" alignRight={true}>
            <LinkContainer to="/dashboard">
              <NavDropdown.Item active={false}>Dashboard</NavDropdown.Item>
            </LinkContainer>

            <LinkContainer to="/settings">
              <NavDropdown.Item active={false}>Settings</NavDropdown.Item>
            </LinkContainer>

            <Dropdown.Divider></Dropdown.Divider>

            <LinkContainer to="/auth/signout">
              <NavDropdown.Item
                active={false}
                onClick={(e) => {
                  e.preventDefault();
                  auth.signout();
                }}
              >
                Sign out
              </NavDropdown.Item>
            </LinkContainer>
          </NavDropdown>
        )}

        {!auth.user && (
          <Nav.Item>
            <LinkContainer to="/auth/signin">
              <Nav.Link active={false}>Sign in</Nav.Link>
            </LinkContainer>
          </Nav.Item>
        )}
      </Nav>
    </>
  );
}

export default ContactPage;
